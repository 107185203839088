import './assets/App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from 'react-router-dom';
import JejumIntermitente from './pages/JejumIntermitente';
import DietaCetogenica from "./pages/DietaCetogenica";
import AlimentacaoFertilidade from "./pages/AlimentacaoFertilidade";
import ReactDOM from "react-dom/client"; 
import News from './pages/News';
import Main from './Main';


var blogUrl = "";

// class App extends Component {
export default function App() {
    return (
      
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Main/>}/>
            <Route exact path="/blog" element={<News/>}/>
            <Route exact path="/blog/jejum-intermitente" element={<JejumIntermitente/>}/>
            <Route exact path="/blog/dieta-cetogenica" element={<DietaCetogenica/>}/>
            <Route exact path="/blog/alimentacao-fertilidade" element={<AlimentacaoFertilidade/>}/>
          </Routes>
        </BrowserRouter>
        
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);