import '../assets/App.css';
import jejum from '../images/jejum-intermitente.jpg'

export default function JejumIntermitente() {
    return (
        <div className='div-news-container'>
            <h1 className='h1-news-title belanosima'>Desvendando o Poder do Jejum Intermitente: Um guia pela sua Nutri, Juliana Oliveira!</h1>
            <h3 className='ruluko'>Olá, amantes da saúde e da boa alimentação! Se você já ouviu falar sobre o jejum intermitente e está curioso para saber mais, você veio ao lugar certo. Prepare-se para mergulhar em um mundo de descobertas nutricionais que podem transformar sua relação com a comida e impulsionar seu bem-estar geral.</h3>
        
            <h1 className='belanosima'>O que é Jejum Intermitente?</h1>
            <h3 className='ruluko'>Em poucas palavras, o jejum intermitente é um padrão alimentar que alterna entre períodos de jejum e alimentação. Não, não estamos falando de passar fome! O jejum intermitente envolve um cronograma específico, onde você se abstém de comer por um período de tempo definido, alternando com períodos de alimentação normal.</h3>
            <img src={jejum} alt="Jejum Intermitente" className='img-center'/>

            <h1 className='belanosima'>Os Poderosos Benefícios do Jejum Intermitente</h1>
            <ul>
                <li className='ruluko'><b>Perda de Peso Saudável:</b> O jejum intermitente pode ser uma ferramenta eficaz para aqueles que buscam perder peso. Ao restringir o período de alimentação, seu corpo pode entrar em um estado onde queima mais gordura como fonte de energia.</li>
                <li className='ruluko'><b>Melhora da Sensibilidade à Insulina:</b> O jejum intermitente pode ajudar a melhorar a sensibilidade à insulina, o que é crucial para a regulação dos níveis de açúcar no sangue e a prevenção do diabetes tipo 2.</li>
                <li className='ruluko'><b>Saúde Cardiovascular:</b>Estudos indicam que o jejum intermitente pode contribuir para a redução de fatores de risco cardiovascular, como pressão arterial elevada e níveis elevados de colesterol.</li>
                <li className='ruluko'><b>Saúde Cerebral:</b> Alguns estudos sugerem que o jejum intermitente pode promover a produção de substâncias químicas cerebrais benéficas, que estão associadas à melhoria da função cerebral e à prevenção de doenças neurodegenerativas.</li>          
            </ul>

            <h1 className='belanosima'>Por que consultar um Nutricionista é Crucial?</h1>
            <h3 className='ruluko'>Enquanto o jejum intermitente oferece uma série de benefícios incríveis, é fundamental abordá-lo de maneira segura e personalizada. Cada indivíduo é único, com diferentes necessidades nutricionais e de saúde. Por isso, a nutri vai te ajudar ajudar a:</h3>
            <ul>
                <li className='ruluko'>Personalizar um plano de jejum intermitente que se adapte ao seu estilo de vida e objetivos.</li>
                <li className='ruluko'>Garantir que você está obtendo os nutrientes essenciais durante os períodos de alimentação.</li>
                <li className='ruluko'>Monitorar seu progresso e fazer ajustes conforme necessário.</li>
            </ul>

            <h1 className='belanosima'>Tire Suas Dúvidas!</h1>
            <h3 className='ruluko'>Se você está ansioso para explorar o mundo do jejum intermitente ou tem perguntas específicas, entre em contato comigo. Estou à disposição para fornecer orientação especializada e esclarecer todas as suas dúvidas.
            Lembre-se, o jejum intermitente é uma ferramenta poderosa, mas não é adequado para todos. Por isso, consultar um nutricionista é o primeiro passo para embarcar nessa jornada com segurança e confiança. </h3>

            <h4 className='h1-news-title'><i>Vamos juntos trilhar o caminho para uma saúde vibrante e equilibrada!</i></h4>
        </div>
    )
}