import '../assets/App.css';
import React, { Component } from 'react';
import jejum from '../images/jejum-intermitente.jpg'
import logo from '../images/logo.jpg';
import alimentacao from '../images/alimentacao-gestante.jpg'
import dieta from '../images/dieta-cetogenica.jpg'


function GreenText({ children }) {
    return (
      <span style={{ color: '#06AC21' }}>{children}</span>
    );
  }
  
  function PurpleText({ children }) {
    return (
      <span style={{ color: '#801074' }}>{children}</span>
    );
  }
  
var jejumIntermitenteNewsUrl = "/blog/jejum-intermitente";
var dietaCetogenicaUrl = "/blog/dieta-cetogenica";
var alimentacaoFertilidadeUrl = "/blog/alimentacao-fertilidade";

export default function News(){
    return (
        <div>
          <header className='header'>
          <img src={logo} alt="Logo de Juliana Oliveira" className='img-logo-blog'/>
          <h1 className='h1-header-blog belanosima'>Blog Juliana Oliveira Nutricionista</h1>
          </header>
            <div className='div-blog-items-blog'>
                <button className='button-news-blog'>
                  <h1 className='belanosima'>Desvendando o Poder do Jejum Intermitente: Um guia pela sua Nutri, Juliana Oliveira!</h1>
                  <img src={jejum} alt="Imagem ilustrativa de notícia do blog" className='img-first-news-blog' />
                  <h2 className='h1-news'>Olá, amantes da saúde e da boa alimentação! Se você já ouviu falar sobre o jejum intermitente e está curioso para saber mais, você veio ao lugar certo. Prepare-se para mergulhar em um mundo de descobertas nutricionais que podem transformar sua relação com a comida e impulsionar seu bem-estar geral.</h2>
                  <button className='button-read-more ruluko' onClick={() => window.location.href = jejumIntermitenteNewsUrl}>LEIA MAIS...</button>
                </button>
                <button className='button-news-blog'>
                  <h1 className='belanosima'>Descubra a Dieta Cetogênica: Transforme sua Saúde com essa Abordagem Nutricional</h1>
                  <img src={dieta} alt="Imagem ilustrativa de notícia do blog" className='img-first-news-blog' />
                  <h2 className='h1-news'>Olá, queridos leitores! Hoje vamos mergulhar em um tema que tem ganhado cada vez mais destaque no mundo da nutrição: a dieta cetogênica. Se você está em busca de uma abordagem nutricional dinâmica e eficaz, continue lendo para descobrir o que é a dieta cetogênica, seus benefícios e como ela pode revolucionar a sua saúde.</h2>
                  <button className='button-read-more ruluko' onClick={() => window.location.href = dietaCetogenicaUrl}>LEIA MAIS...</button>
                </button>
                <button className='button-news-blog'>
                  <h1 className='belanosima'>Alimentos que Promovem a Fertilidade: Nutrição para um Futuro Brilhante</h1>
                  <img src={alimentacao} alt="Imagem ilustrativa de notícia do blog" className='img-first-news-blog' />
                  <h2 className='h1-news'>No mundo da nutrição, escolher os alimentos certos pode desempenhar um papel significativo na saúde reprodutiva. Se você está pensando em aumentar suas chances de fertilidade, considere adicionar esses alimentos saudáveis à sua dieta.</h2>
                  <button className='button-read-more ruluko' onClick={() => window.location.href = alimentacaoFertilidadeUrl}>LEIA MAIS...</button>
                </button>
            </div>
        </div>
    )
}