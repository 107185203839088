import '../assets/App.css';
import dieta from '../images/dieta-cetogenica.jpg'


export default function DietaCetogenica() {
    return (
        <div className='div-news-container'>
            <h1 className='h1-news-title belanosima'>Descubra a Dieta Cetogênica: Transforme sua Saúde com essa Abordagem Nutricional</h1>
            <h3 className='ruluko'>Olá, queridos leitores! Hoje vamos mergulhar em um tema que tem ganhado cada vez mais destaque no mundo da nutrição: a dieta cetogênica. Se você está em busca de uma abordagem nutricional dinâmica e eficaz, continue lendo para descobrir o que é a dieta cetogênica, seus benefícios e como ela pode revolucionar a sua saúde.</h3>
        
            <h1 className='belanosima'>O Que é a Dieta Cetogênica?</h1>
            <h3 className='ruluko'>A dieta cetogênica é um plano alimentar que se concentra em reduzir drasticamente a ingestão de carboidratos e aumentar o consumo de gorduras saudáveis. Ao fazer isso, o corpo entra em um estado metabólico chamado cetose, no qual passa a queimar gordura como fonte primária de energia em vez de carboidratos. Isso pode levar a uma perda de peso eficaz e outros benefícios impressionantes.</h3>

            <h1 className='belanosima'>Benefícios da Dieta Cetogênica:</h1>
            <ul>
                <li className='ruluko'><b>Perda de Peso Sustentável:</b> A queima de gordura constante devido à cetose pode ajudar a promover a perda de peso de maneira sustentável, além de reduzir a fome e os desejos por alimentos ricos em carboidratos.</li>
                <li className='ruluko'><b>Melhora da Saúde Cardiovascular:</b> A dieta cetogênica tem sido associada a melhorias nos níveis de colesterol e triglicerídeos, reduzindo o risco de doenças cardiovasculares.</li>
                <li className='ruluko'><b>Aumento de Energia e Foco: </b>Com uma fonte constante de energia proveniente da queima de gordura, muitas pessoas relatam um aumento nos níveis de energia e uma melhora na clareza mental.</li>
                <li className='ruluko'><b>Controle do Açúcar no Sangue:</b> A dieta cetogênica pode ajudar a estabilizar os níveis de glicose no sangue, sendo uma opção valiosa para pessoas com diabetes tipo 2.</li>          
                <li className='ruluko'><b>Suporte ao Emagrecimento Saúde:</b> Além de ajudar na perda de peso, a dieta cetogênica pode auxiliar na redução da gordura abdominal, que está associada a riscos metabólicos mais altos.</li>          
            </ul>

            <img src={dieta} alt="Dieta Cetogenica" className='img-center'/>

            <h1 className='belanosima'>Dicas para Começar:</h1>
            <ul>
                <li className='ruluko'><b>Planejamento é Fundamental:</b> A dieta cetogênica pode ajudar a estabilizar os níveis de glicose no sangue, sendo uma opção valiosa para pessoas com diabetes tipo 2.</li>          
                <li className='ruluko'><b>Escolha Fontes de Gordura Saudáveis:</b> A dieta cetogênica pode ajudar a estabilizar os níveis de glicose no sangue, sendo uma opção valiosa para pessoas com diabetes tipo 2.</li>          
                <li className='ruluko'><b>Monitore a Ingestão de Proteínas:</b> Consuma uma quantidade moderada de proteínas para evitar a quebra da cetose.</li>          
                <li className='ruluko'><b>Hidrate-se Adequadamente:</b> Mantenha-se hidratado, pois a cetose pode levar à perda de água.</li>
            </ul>

            <h1 className='belanosima'>Tire Suas Dúvidas!</h1>
            <h3 className='ruluko'>Antes de iniciar qualquer dieta, é importante consultar um profissional de saúde ou um nutricionista para garantir que a dieta cetogênica seja segura e adequada às suas necessidades individuais.</h3>
            <h3 className='ruluko'>Em resumo, a dieta cetogênica é muito mais do que apenas uma tendência passageira. Se você está procurando uma abordagem nutricional dinâmica para transformar sua saúde e alcançar seus objetivos, essa dieta pode ser a escolha certa para você. Lembre-se sempre de buscar orientação profissional e aproveite os incríveis benefícios que essa dieta pode oferecer. Estamos aqui para te guiar nessa jornada rumo a uma saúde melhor!</h3>

            <h4 className='h1-news-title'><i>Gostou do artigo? Compartilhe sua opinião nos comentários e fique ligado para mais dicas de nutrição saudável. Até a próxima!</i></h4>
        </div>
    )
}