import '../assets/App.css';
import alimentacao from '../images/alimentacao-gestante.jpg'


export default function AlimentacaoFertilidade() {
    return (
        <div className='div-news-container'>
            <h1 className='h1-news-title belanosima'>Alimentos que Promovem a Fertilidade: Nutrição para um Futuro Brilhante</h1>
            <h3 className='ruluko'>No mundo da nutrição, escolher os alimentos certos pode desempenhar um papel significativo na saúde reprodutiva. Se você está pensando em aumentar suas chances de fertilidade, considere adicionar esses alimentos saudáveis à sua dieta:</h3>
        
            <img src={alimentacao} alt="Alimentacao gestante" className='img-center'/>

            <ul>
                <li className='ruluko'><b>Vegetais de Folhas Verdes:</b> Espinafre, couve e outras folhas verdes são ricos em ácido fólico e antioxidantes que podem melhorar a saúde do esperma e dos óvulos.</li>
                <li className='ruluko'><b>Frutas Coloridas:</b> Frutas como morangos, mirtilos e laranjas são carregadas com vitamina C e antioxidantes, que podem proteger as células reprodutivas de danos.</li>
                <li className='ruluko'><b>Grãos Integrais:</b>Opte por grãos integrais em vez de refinados. Eles ajudam a regular os níveis de insulina, o que pode ter um impacto positivo na fertilidade.</li>
                <li className='ruluko'><b>Proteínas de Alta Qualidade:</b> Fontes magras de proteína, como peixe, frango, ovos e leguminosas, fornecem aminoácidos essenciais que são cruciais para a produção de hormônios reprodutivos.</li>          
                <li className='ruluko'><b>Gorduras Saudáveis:</b> Abacate, nozes e azeite de oliva contêm ácidos graxos ômega-3 e ômega-6, que são importantes para a saúde hormonal.</li>          
                <li className='ruluko'><b>Laticínios com Baixo Teor de Gordura:</b> Opte por laticínios com baixo teor de gordura, como iogurte e leite desnatado, pois podem ajudar a melhorar a ovulação.</li>          
                <li className='ruluko'><b>Alimentos Ricos em Ferro:</b> Carnes magras, feijões, lentilhas e vegetais de folhas escuras são ótimas fontes de ferro, que é fundamental para a saúde reprodutiva.</li>          
                <li className='ruluko'><b>Alimentos Ricos em Zinco:</b> Sementes de abóbora, carne vermelha magra e grãos integrais são ricos em zinco, um mineral importante para a produção de hormônios sexuais.</li>          
            </ul>

            <h3 className='ruluko'>Lembre-se de que a moderação é a chave. Uma dieta equilibrada e saudável é essencial para a fertilidade, mas consulte sempre um profissional de saúde antes de fazer grandes alterações em sua dieta. Combinando escolhas alimentares inteligentes com um estilo de vida saudável, você estará no caminho certo para promover a sua fertilidade e alcançar seus objetivos reprodutivos.</h3>
        </div>
    )
}