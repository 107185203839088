import './assets/App.css';
import React, { Component } from 'react';
import logo from './images/logo.jpg';
import cropped_logo from './images/logo-cropped.PNG';
import profile1 from './images/profile-1.png';
import profile2 from './images/profile-2.png';
import profile3cropped from './images/profile-3-cropped.png';
import instagram_header from './images/instagram-header.PNG'
import profile1cropped from './images/profile-1-cropped.png';
import cropped_detail from './images/cropped-detail-1.png';
import pacients_feedback from './images/feedback-atualizado.png'
import SearchIcon from '@mui/icons-material/Search';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import icon from './images/icon.png';
import whatsapp_logo from './images/whatsapp-logo.png'
import TouchAppIcon from '@mui/icons-material/TouchApp';
import jejum from './images/jejum-intermitente.jpg'
import dietaCetogenica from './images/dieta-cetogenica.jpg'
import alimentacaoGestante from './images/alimentacao-gestante.jpg'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function BoldText({ children }) {
  return (
    <span style={{ fontWeight: 900 }}>{children}</span>
  );
}

function GreenText({ children }) {
  return (
    <span style={{ color: '#06AC21' }}>{children}</span>
  );
}

function PurpleText({ children }) {
  return (
    <span style={{ color: '#801074' }}>{children}</span>
  );
}

var whatsappUrl = 'https://api.whatsapp.com/send?phone=5511916573592&text=Ol%C3%A1,%20Dra.%20Juliana,%20gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20consultas%20e%20atendimento%20Nutricional.'
var instagramUrl = 'https://www.instagram.com/julianaoliveira__nutri/'
var blogUrl = 'https://www.julianaoliveiranutri.com.br/blog/'
var jejumIntermitenteNewsUrl = 'https://www.julianaoliveiranutri.com.br/blog/jejum-intermitente'
var dietaCetogenicaNewsUrl = 'https://www.julianaoliveiranutri.com.br/blog/dieta-cetogenica'
var alimentacaoFertilidadeNewsUrl = 'https://www.julianaoliveiranutri.com.br/blog/alimentacao-fertilidade'

// class App extends Component {
export default function Main() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500, 
    slidesToShow: 1,
    slidesToScroll: 1
  }
    return(
      <div>
        <div className='whatsapp-float'>
          <a href={whatsappUrl}><img src={whatsapp_logo} width="70px" style={{'border-radius':'20px'}}/></a>
        </div>
        <Popup
          open={true}
          // trigger={<button className="button" > Open Modal </button>}
          modal
          nested
          className='popup'
        >
          {close => (
            <div className="modal">
              <button className="button-close" onClick={close}>
                &times;
              </button>
              <div className="header-popup belanosima"> Siga a nutri no Instagram! </div>
              <img src={instagram_header} className='img-instagram-header'/>
              
            </div>
          )}
        </Popup>
        <div className='div-presentation'>
          <div className='div-header-text'>
            <img src={logo} alt="Logo de Juliana Oliveira" className='img-logo'/>
            <img src={cropped_logo} alt="Logo de Juliana Oliveira" className='img-logo-cropped'/>
            <h1 className='h1-presentation roboto'><PurpleText>Você está a um passo de <BoldText>emagrecer</BoldText> de forma <BoldText><GreenText>leve</GreenText></BoldText> e <BoldText><GreenText>plena</GreenText></BoldText></PurpleText></h1>
            <p className='h1-presentation ruluko'>Ajudo você a cuidar mais de si e a experimentar o prazer do estilo de vida saudável, com uma alimentação leve e sem muitas restrições.</p>
            <button className='button-schedule ruluko' onClick={event =>  window.location.href=whatsappUrl}>AGENDE SUA CONSULTA <SearchIcon/></button>
          </div>
          <div className='div-header-image'>
            <img src={profile1} alt="Foto de apresentação de Juliana Oliveira" className='img-presentation'/>
            <img src={profile1cropped} alt="Foto de apresentação de Juliana Oliveira" className='img-presentation-cropped'/>
          </div>
        </div>

        <div className='div-segments'>
          <button className='button-segment ruluko'><MenuBookIcon/> REEDUCAÇÃO ALIMENTAR </button>
          <button className='button-segment ruluko'><DirectionsRunIcon/> NUTRIÇÃO ESPORTIVA</button>
          <button className='button-segment ruluko'><MonitorWeightIcon/> EMAGRECIMENTO</button>
        </div>

        <div className='div-results'>
          <div className='div-results-box'>
            <h1 className='h1-centralized-content belanosima'><PurpleText>RESULTADOS</PurpleText></h1>
            <button className='button-subtitle ruluko'>Conheça alguns dos nossos feedbacks...</button>
            <img src={pacients_feedback} alt="Feedback de pacientes de Juliana Oliveira" className='img-feedback'></img>
            <h2 className='h1-subheader belanosima'><PurpleText>Resultados obtidos em 21 dias!</PurpleText></h2>

          </div>
          <h1 className='h1-presentation-description belanosima'>Se você deseja ter resultados assim...</h1>
          <button className='button-schedule ruluko' onClick={event =>  window.location.href=whatsappUrl}>AGENDE SUA CONSULTA <SearchIcon/></button>
        </div>

        

        <div className='div-reasoning'>
          <img src={cropped_detail} alt="Maçã no centro de uma fita métrica" className='img-cropped-detail'></img>
          <div className='div-centralized-content'>
            <h1 className='h1-centralized-content belanosima'>POR QUE PRECISO DE UMA NUTRICIONISTA?</h1>
            <ul className='ul-habits belanosima'>
              <li><CheckCircleIcon/>Melhora os hábitos alimentares</li>
              <li><CheckCircleIcon/>Melhora a qualidade do sono</li>
              <li><CheckCircleIcon/>Previne enfermidades/doenças</li>
              <li><CheckCircleIcon/>Ajuda a aumentar a disposição e bem-estar</li>
              <li><CheckCircleIcon/>Ajuda a chegar ao peso ideal</li>
              <li><CheckCircleIcon/>Ajuda na organização do seu plano alimentar de acordo com sua preferência e rotina</li>
            </ul>
          </div>
        </div>

        <img src={profile2} alt="Foto de perfil de Juliana Oliveira" className='img-profile-mobile'></img>

        <div className='div-about'>
          <img src={profile2} alt="Foto de perfil de Juliana Oliveira" className='img-profile'></img>
          <div className='div-about-presentation'>
            <h1 className='h1-presentation-header'><PurpleText>SOBRE A NUTRI</PurpleText></h1>
            <h1 className='h1-presentation-header'><GreenText>JULIANA OLIVEIRA</GreenText></h1>
            <h1 className='h1-presentation-description belanosima'>Juliana Oliveira é uma profissional qualificada formada em Nutrição - CRN-3 73206, especialista em Emagrecimento. </h1>
            <h1 className='h1-presentation-description belanosima'>O meu maior prazer na área de nutrição não é só o emagrecimento, e sim, ajudar a melhorar a qualidade de vida através de uma alimentação leve, plena e sem muitas restrições.</h1>
            <button className='button-schedule ruluko' onClick={event =>  window.location.href=whatsappUrl}>AGENDE SUA CONSULTA <SearchIcon/></button>
          </div>
        </div>

        

        <div style={{'text-align':'center'}}>
          <h1 className='h1-centralized-content belanosima'>NOSSO BLOG</h1>
            <div className='div-blog-items'>
                <button className='button-news'>
                  <h1><GreenText>NOTÍCIA 1</GreenText></h1>
                  <img src={jejum} alt="Imagem ilustrativa de notícia do blog" className='img-blog-article' />
                  <h1 className='h1-presentation-description belanosima'>Desvendando o Poder do Jejum Intermitente: Um guia pela sua Nutri, Juliana Oliveira!</h1>

                  <button className='button-read-more ruluko' onClick={() => window.location.href = jejumIntermitenteNewsUrl}>LEIA MAIS...</button>
                </button>
                <button className='button-news'>
                <h1 className='belanosima'><GreenText>NOTÍCIA 2</GreenText></h1>

                  <img src={dietaCetogenica} alt="Imagem ilustrativa de notícia do blog" className='img-blog-article' />
                  <h1 className='h1-presentation-description belanosima'>Descubra a Dieta Cetogênica: Transforme sua Saúde com essa Abordagem Nutricional</h1>

                  <button className='button-read-more ruluko' onClick={() => window.location.href = dietaCetogenicaNewsUrl}>LEIA MAIS...</button>
                </button>
                <button className='button-news'>
                <h1><GreenText>NOTÍCIA 2</GreenText></h1>
                  
                  <img src={alimentacaoGestante} alt="Imagem ilustrativa de notícia do blog" className='img-blog-article' />
                  <h1 className='h1-presentation-description belanosima'>Alimentos que Promovem a Fertilidade: Nutrição para um Futuro Brilhante</h1>
                  
                  <button className='button-read-more ruluko' onClick={() => window.location.href = alimentacaoFertilidadeNewsUrl}>LEIA MAIS...</button>
                </button>
            </div>
            
          <h1 className='h1-blog-description'>Saiba mais sobre saúde/emagrecimento/nutrição no meu blog! Clique no botão abaixo para acessá-lo. Lá você encontrará artigos interessantes sobre diversos temas, dicas e truques para manter-se sempre em boa forma.</h1>
          <button className='button-articles ruluko' onClick={event =>  window.location.href=blogUrl}>VER MAIS ARTIGOS<TouchAppIcon/></button>

        </div>
        <img src={profile3cropped} className='img-footer'/>

        <div className='div-footer'>
          <div></div>

          <div className='div-footer-information' >
            <h1 className='h1-footer belanosima'>AGENDE SUA CONSULTA AGORA MESMO</h1>
            <button className='button-instagram' onClick={event =>  window.location.href=instagramUrl}></button>
            <button className='button-whatsapp' onClick={event =>  window.location.href=whatsappUrl}></button>
            <h1 className='h1-footer-message belanosima'>Busque por mudanças e esteja pronto(a) para ver seu objetivo cumprido!</h1>
          </div>
        </div>


        
      </div>
    );
}
